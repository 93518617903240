import { setContent, toEventWithLocalDates, toViewWithLocalDates, createEventClasses, createEventContent, addDay, cloneDate, bgEvent, eventIntersects, createEventChunk, sortEventChunks, datesEqual, setPayload, btnTextDay, themeView, btnTextWeek, btnTextMonth, btnTextYear, intl } from '@event-calendar/core';
import { SvelteComponent, init, safe_not_equal, create_slot, element, attr, insert, append, update_slot_base, get_all_dirty_from_scope, get_slot_changes, transition_in, transition_out, detach, component_subscribe, binding_callbacks, space, action_destroyer, listen, is_function, noop, run_all, empty, group_outros, check_outros, ensure_array_like, update_keyed_each, outro_and_destroy_block, create_component, mount_component, destroy_component, destroy_each } from 'svelte/internal';
import { getContext, onMount } from 'svelte';

/* packages\list\src\Body.svelte generated by Svelte v4.2.2 */

function create_fragment$3(ctx) {
	let div1;
	let div0;
	let div0_class_value;
	let div1_class_value;
	let current;
	const default_slot_template = /*#slots*/ ctx[5].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[4], null);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			if (default_slot) default_slot.c();
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].content);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].body);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			if (default_slot) {
				default_slot.m(div0, null);
			}

			/*div1_binding*/ ctx[6](div1);
			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 16)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[4],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[4])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[4], dirty, null),
						null
					);
				}
			}

			if (!current || dirty & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].content)) {
				attr(div0, "class", div0_class_value);
			}

			if (!current || dirty & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].body)) {
				attr(div1, "class", div1_class_value);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div1);
			}

			if (default_slot) default_slot.d(detaching);
			/*div1_binding*/ ctx[6](null);
		}
	};
}

function instance$3($$self, $$props, $$invalidate) {
	let $theme;
	let $_bodyEl;
	let { $$slots: slots = {}, $$scope } = $$props;
	let { _bodyEl, theme } = getContext('state');
	component_subscribe($$self, _bodyEl, value => $$invalidate(1, $_bodyEl = value));
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));

	function div1_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			$_bodyEl = $$value;
			_bodyEl.set($_bodyEl);
		});
	}

	$$self.$$set = $$props => {
		if ('$$scope' in $$props) $$invalidate(4, $$scope = $$props.$$scope);
	};

	return [$theme, $_bodyEl, _bodyEl, theme, $$scope, slots, div1_binding];
}

class Body extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$3, create_fragment$3, safe_not_equal, {});
	}
}

/* packages\list\src\Event.svelte generated by Svelte v4.2.2 */

function create_fragment$2(ctx) {
	let div2;
	let div0;
	let div0_class_value;
	let t;
	let div1;
	let div1_class_value;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			t = space();
			div1 = element("div");
			attr(div0, "class", div0_class_value = /*$theme*/ ctx[0].eventTag);
			attr(div0, "style", /*style*/ ctx[3]);
			attr(div1, "class", div1_class_value = /*$theme*/ ctx[0].eventBody);
			attr(div2, "class", /*classes*/ ctx[2]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			append(div2, t);
			append(div2, div1);
			/*div2_binding*/ ctx[36](div2);

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div1, /*content*/ ctx[4])),
					listen(div2, "click", function () {
						if (is_function(/*createHandler*/ ctx[23](/*$eventClick*/ ctx[5]))) /*createHandler*/ ctx[23](/*$eventClick*/ ctx[5]).apply(this, arguments);
					}),
					listen(div2, "mouseenter", function () {
						if (is_function(/*createHandler*/ ctx[23](/*$eventMouseEnter*/ ctx[6]))) /*createHandler*/ ctx[23](/*$eventMouseEnter*/ ctx[6]).apply(this, arguments);
					}),
					listen(div2, "mouseleave", function () {
						if (is_function(/*createHandler*/ ctx[23](/*$eventMouseLeave*/ ctx[7]))) /*createHandler*/ ctx[23](/*$eventMouseLeave*/ ctx[7]).apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*$theme*/ 1 && div0_class_value !== (div0_class_value = /*$theme*/ ctx[0].eventTag)) {
				attr(div0, "class", div0_class_value);
			}

			if (dirty[0] & /*style*/ 8) {
				attr(div0, "style", /*style*/ ctx[3]);
			}

			if (dirty[0] & /*$theme*/ 1 && div1_class_value !== (div1_class_value = /*$theme*/ ctx[0].eventBody)) {
				attr(div1, "class", div1_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty[0] & /*content*/ 16) setContent_action.update.call(null, /*content*/ ctx[4]);

			if (dirty[0] & /*classes*/ 4) {
				attr(div2, "class", /*classes*/ ctx[2]);
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div2);
			}

			/*div2_binding*/ ctx[36](null);
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance$2($$self, $$props, $$invalidate) {
	let $_view;
	let $eventDidMount;
	let $_intlEventTime;
	let $theme;
	let $eventContent;
	let $displayEventEnd;
	let $eventClassNames;
	let $eventTextColor;
	let $_resTxtColor;
	let $eventColor;
	let $eventBackgroundColor;
	let $_resBgColor;
	let $eventClick;
	let $eventMouseEnter;
	let $eventMouseLeave;
	let { chunk } = $$props;
	let { displayEventEnd, eventBackgroundColor, eventTextColor, eventColor, eventContent, eventClassNames, eventClick, eventDidMount, eventMouseEnter, eventMouseLeave, theme, _view, _intlEventTime, _resBgColor, _resTxtColor } = getContext('state');
	component_subscribe($$self, displayEventEnd, value => $$invalidate(29, $displayEventEnd = value));
	component_subscribe($$self, eventBackgroundColor, value => $$invalidate(34, $eventBackgroundColor = value));
	component_subscribe($$self, eventTextColor, value => $$invalidate(31, $eventTextColor = value));
	component_subscribe($$self, eventColor, value => $$invalidate(33, $eventColor = value));
	component_subscribe($$self, eventContent, value => $$invalidate(28, $eventContent = value));
	component_subscribe($$self, eventClassNames, value => $$invalidate(30, $eventClassNames = value));
	component_subscribe($$self, eventClick, value => $$invalidate(5, $eventClick = value));
	component_subscribe($$self, eventDidMount, value => $$invalidate(38, $eventDidMount = value));
	component_subscribe($$self, eventMouseEnter, value => $$invalidate(6, $eventMouseEnter = value));
	component_subscribe($$self, eventMouseLeave, value => $$invalidate(7, $eventMouseLeave = value));
	component_subscribe($$self, theme, value => $$invalidate(0, $theme = value));
	component_subscribe($$self, _view, value => $$invalidate(26, $_view = value));
	component_subscribe($$self, _intlEventTime, value => $$invalidate(27, $_intlEventTime = value));
	component_subscribe($$self, _resBgColor, value => $$invalidate(35, $_resBgColor = value));
	component_subscribe($$self, _resTxtColor, value => $$invalidate(32, $_resTxtColor = value));
	let el;
	let event;
	let classes;
	let style;
	let content;
	let timeText;

	onMount(() => {
		if (is_function($eventDidMount)) {
			$eventDidMount({
				event: toEventWithLocalDates(event),
				timeText,
				el,
				view: toViewWithLocalDates($_view)
			});
		}
	});

	function createHandler(fn) {
		return jsEvent => {
			if (is_function(fn)) {
				fn({
					event: toEventWithLocalDates(event),
					el,
					jsEvent,
					view: toViewWithLocalDates($_view)
				});
			}
		};
	}

	function div2_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$set = $$props => {
		if ('chunk' in $$props) $$invalidate(24, chunk = $$props.chunk);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*chunk*/ 16777216) {
			$$invalidate(25, event = chunk.event);
		}

		if ($$self.$$.dirty[0] & /*event, style, $theme, $eventClassNames, $_view*/ 1174405129 | $$self.$$.dirty[1] & /*$_resBgColor, $eventBackgroundColor, $eventColor, $_resTxtColor, $eventTextColor*/ 31) {
			{
				// Class & Style
				$$invalidate(3, style = '');

				let bgColor = event.backgroundColor || $_resBgColor(event) || $eventBackgroundColor || $eventColor;

				if (bgColor) {
					$$invalidate(3, style = `background-color:${bgColor};`);
				}

				let txtColor = event.textColor || $_resTxtColor(event) || $eventTextColor;

				if (txtColor) {
					$$invalidate(3, style += `color:${txtColor};`);
				}

				$$invalidate(2, classes = [$theme.event, ...createEventClasses($eventClassNames, event, $_view)].join(' '));
			}
		}

		if ($$self.$$.dirty[0] & /*chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view*/ 1023410177) {
			{
				// Content
				$$invalidate(4, [timeText, content] = createEventContent(chunk, $displayEventEnd, $eventContent, $theme, $_intlEventTime, $_view), content);
			}
		}
	};

	return [
		$theme,
		el,
		classes,
		style,
		content,
		$eventClick,
		$eventMouseEnter,
		$eventMouseLeave,
		displayEventEnd,
		eventBackgroundColor,
		eventTextColor,
		eventColor,
		eventContent,
		eventClassNames,
		eventClick,
		eventDidMount,
		eventMouseEnter,
		eventMouseLeave,
		theme,
		_view,
		_intlEventTime,
		_resBgColor,
		_resTxtColor,
		createHandler,
		chunk,
		event,
		$_view,
		$_intlEventTime,
		$eventContent,
		$displayEventEnd,
		$eventClassNames,
		$eventTextColor,
		$_resTxtColor,
		$eventColor,
		$eventBackgroundColor,
		$_resBgColor,
		div2_binding
	];
}

class Event extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$2, create_fragment$2, safe_not_equal, { chunk: 24 }, null, [-1, -1]);
	}
}

/* packages\list\src\Day.svelte generated by Svelte v4.2.2 */

function get_each_context$1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[20] = list[i];
	return child_ctx;
}

// (46:0) {#if chunks.length}
function create_if_block$1(ctx) {
	let div;
	let span0;
	let setContent_action;
	let t0;
	let span1;
	let span1_class_value;
	let setContent_action_1;
	let div_class_value;
	let t1;
	let each_blocks = [];
	let each_1_lookup = new Map();
	let each_1_anchor;
	let current;
	let mounted;
	let dispose;
	let each_value = ensure_array_like(/*chunks*/ ctx[2]);
	const get_key = ctx => /*chunk*/ ctx[20].event;

	for (let i = 0; i < each_value.length; i += 1) {
		let child_ctx = get_each_context$1(ctx, each_value, i);
		let key = get_key(child_ctx);
		each_1_lookup.set(key, each_blocks[i] = create_each_block$1(key, child_ctx));
	}

	return {
		c() {
			div = element("div");
			span0 = element("span");
			t0 = space();
			span1 = element("span");
			t1 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
			attr(span1, "class", span1_class_value = /*$theme*/ ctx[5].daySide);

			attr(div, "class", div_class_value = "" + (/*$theme*/ ctx[5].day + " " + /*$theme*/ ctx[5].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[3] ? ' ' + /*$theme*/ ctx[5].today : '') + (/*highlight*/ ctx[4]
			? ' ' + /*$theme*/ ctx[5].highlight
			: '')));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, span0);
			append(div, t0);
			append(div, span1);
			/*div_binding*/ ctx[19](div);
			insert(target, t1, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, span0, /*$_intlListDay*/ ctx[7].format(/*date*/ ctx[0]))),
					action_destroyer(setContent_action_1 = setContent.call(null, span1, /*$_intlListDaySide*/ ctx[8].format(/*date*/ ctx[0]))),
					listen(div, "pointerdown", function () {
						if (is_function(/*$_interaction*/ ctx[6].action?.select)) /*$_interaction*/ ctx[6].action?.select.apply(this, arguments);
					})
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			if (setContent_action && is_function(setContent_action.update) && dirty & /*$_intlListDay, date*/ 129) setContent_action.update.call(null, /*$_intlListDay*/ ctx[7].format(/*date*/ ctx[0]));

			if (!current || dirty & /*$theme*/ 32 && span1_class_value !== (span1_class_value = /*$theme*/ ctx[5].daySide)) {
				attr(span1, "class", span1_class_value);
			}

			if (setContent_action_1 && is_function(setContent_action_1.update) && dirty & /*$_intlListDaySide, date*/ 257) setContent_action_1.update.call(null, /*$_intlListDaySide*/ ctx[8].format(/*date*/ ctx[0]));

			if (!current || dirty & /*$theme, date, isToday, highlight*/ 57 && div_class_value !== (div_class_value = "" + (/*$theme*/ ctx[5].day + " " + /*$theme*/ ctx[5].weekdays?.[/*date*/ ctx[0].getUTCDay()] + (/*isToday*/ ctx[3] ? ' ' + /*$theme*/ ctx[5].today : '') + (/*highlight*/ ctx[4]
			? ' ' + /*$theme*/ ctx[5].highlight
			: '')))) {
				attr(div, "class", div_class_value);
			}

			if (dirty & /*chunks*/ 4) {
				each_value = ensure_array_like(/*chunks*/ ctx[2]);
				group_outros();
				each_blocks = update_keyed_each(each_blocks, dirty, get_key, 1, ctx, each_value, each_1_lookup, each_1_anchor.parentNode, outro_and_destroy_block, create_each_block$1, each_1_anchor, get_each_context$1);
				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(div);
				detach(t1);
				detach(each_1_anchor);
			}

			/*div_binding*/ ctx[19](null);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].d(detaching);
			}

			mounted = false;
			run_all(dispose);
		}
	};
}

// (55:4) {#each chunks as chunk (chunk.event)}
function create_each_block$1(key_1, ctx) {
	let first;
	let event;
	let current;
	event = new Event({ props: { chunk: /*chunk*/ ctx[20] } });

	return {
		key: key_1,
		first: null,
		c() {
			first = empty();
			create_component(event.$$.fragment);
			this.first = first;
		},
		m(target, anchor) {
			insert(target, first, anchor);
			mount_component(event, target, anchor);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;
			const event_changes = {};
			if (dirty & /*chunks*/ 4) event_changes.chunk = /*chunk*/ ctx[20];
			event.$set(event_changes);
		},
		i(local) {
			if (current) return;
			transition_in(event.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(event.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(first);
			}

			destroy_component(event, detaching);
		}
	};
}

function create_fragment$1(ctx) {
	let if_block_anchor;
	let current;
	let if_block = /*chunks*/ ctx[2].length && create_if_block$1(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			if (/*chunks*/ ctx[2].length) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*chunks*/ 4) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block$1(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(if_block_anchor);
			}

			if (if_block) if_block.d(detaching);
		}
	};
}

function instance$1($$self, $$props, $$invalidate) {
	let $highlightedDates;
	let $_today;
	let $_events;
	let $theme;
	let $_interaction;
	let $_intlListDay;
	let $_intlListDaySide;
	let { date } = $$props;
	let { _events, _interaction, _intlListDay, _intlListDaySide, _today, highlightedDates, theme } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(18, $_events = value));
	component_subscribe($$self, _interaction, value => $$invalidate(6, $_interaction = value));
	component_subscribe($$self, _intlListDay, value => $$invalidate(7, $_intlListDay = value));
	component_subscribe($$self, _intlListDaySide, value => $$invalidate(8, $_intlListDaySide = value));
	component_subscribe($$self, _today, value => $$invalidate(17, $_today = value));
	component_subscribe($$self, highlightedDates, value => $$invalidate(16, $highlightedDates = value));
	component_subscribe($$self, theme, value => $$invalidate(5, $theme = value));
	let el;
	let chunks;
	let isToday, highlight;

	function div_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			el = $$value;
			$$invalidate(1, el);
		});
	}

	$$self.$$set = $$props => {
		if ('date' in $$props) $$invalidate(0, date = $$props.date);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*date, $_events, chunks*/ 262149) {
			{
				$$invalidate(2, chunks = []);
				let start = date;
				let end = addDay(cloneDate(date));

				for (let event of $_events) {
					if (!bgEvent(event.display) && eventIntersects(event, start, end)) {
						let chunk = createEventChunk(event, start, end);
						chunks.push(chunk);
					}
				}

				sortEventChunks(chunks);
			}
		}

		if ($$self.$$.dirty & /*date, $_today*/ 131073) {
			$$invalidate(3, isToday = datesEqual(date, $_today));
		}

		if ($$self.$$.dirty & /*$highlightedDates, date*/ 65537) {
			$$invalidate(4, highlight = $highlightedDates.some(d => datesEqual(d, date)));
		}

		if ($$self.$$.dirty & /*el, date*/ 3) {
			// dateFromPoint
			if (el) {
				setPayload(el, () => ({
					allDay: true,
					date,
					resource: undefined,
					dayEl: el
				}));
			}
		}
	};

	return [
		date,
		el,
		chunks,
		isToday,
		highlight,
		$theme,
		$_interaction,
		$_intlListDay,
		$_intlListDaySide,
		_events,
		_interaction,
		_intlListDay,
		_intlListDaySide,
		_today,
		highlightedDates,
		theme,
		$highlightedDates,
		$_today,
		$_events,
		div_binding
	];
}

class Day extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance$1, create_fragment$1, safe_not_equal, { date: 0 });
	}
}

/* packages\list\src\View.svelte generated by Svelte v4.2.2 */

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[15] = list[i];
	return child_ctx;
}

// (45:0) {:else}
function create_else_block(ctx) {
	let each_1_anchor;
	let current;
	let each_value = ensure_array_like(/*$_viewDates*/ ctx[1]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			each_1_anchor = empty();
		},
		m(target, anchor) {
			for (let i = 0; i < each_blocks.length; i += 1) {
				if (each_blocks[i]) {
					each_blocks[i].m(target, anchor);
				}
			}

			insert(target, each_1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$_viewDates*/ 2) {
				each_value = ensure_array_like(/*$_viewDates*/ ctx[1]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(each_1_anchor.parentNode, each_1_anchor);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(each_1_anchor);
			}

			destroy_each(each_blocks, detaching);
		}
	};
}

// (41:0) {#if noEvents}
function create_if_block(ctx) {
	let div;
	let div_class_value;
	let setContent_action;
	let mounted;
	let dispose;

	return {
		c() {
			div = element("div");
			attr(div, "class", div_class_value = /*$theme*/ ctx[3].noEvents);
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (!mounted) {
				dispose = [
					action_destroyer(setContent_action = setContent.call(null, div, /*content*/ ctx[0])),
					listen(div, "click", /*handleClick*/ ctx[10])
				];

				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*$theme*/ 8 && div_class_value !== (div_class_value = /*$theme*/ ctx[3].noEvents)) {
				attr(div, "class", div_class_value);
			}

			if (setContent_action && is_function(setContent_action.update) && dirty & /*content*/ 1) setContent_action.update.call(null, /*content*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) {
				detach(div);
			}

			mounted = false;
			run_all(dispose);
		}
	};
}

// (46:4) {#each $_viewDates as date}
function create_each_block(ctx) {
	let day;
	let current;
	day = new Day({ props: { date: /*date*/ ctx[15] } });

	return {
		c() {
			create_component(day.$$.fragment);
		},
		m(target, anchor) {
			mount_component(day, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const day_changes = {};
			if (dirty & /*$_viewDates*/ 2) day_changes.date = /*date*/ ctx[15];
			day.$set(day_changes);
		},
		i(local) {
			if (current) return;
			transition_in(day.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(day.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(day, detaching);
		}
	};
}

// (40:0) <Body>
function create_default_slot(ctx) {
	let current_block_type_index;
	let if_block;
	let if_block_anchor;
	let current;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*noEvents*/ ctx[2]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_blocks[current_block_type_index].m(target, anchor);
			insert(target, if_block_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(if_block_anchor.parentNode, if_block_anchor);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) {
				detach(if_block_anchor);
			}

			if_blocks[current_block_type_index].d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let body;
	let current;

	body = new Body({
			props: {
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(body.$$.fragment);
		},
		m(target, anchor) {
			mount_component(body, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const body_changes = {};

			if (dirty & /*$$scope, $theme, content, noEvents, $_viewDates*/ 262159) {
				body_changes.$$scope = { dirty, ctx };
			}

			body.$set(body_changes);
		},
		i(local) {
			if (current) return;
			transition_in(body.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(body.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(body, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_view;
	let $noEventsClick;
	let $noEventsContent;
	let $_events;
	let $_viewDates;
	let $theme;
	let { _events, _view, _viewDates, noEventsClick, noEventsContent, theme } = getContext('state');
	component_subscribe($$self, _events, value => $$invalidate(12, $_events = value));
	component_subscribe($$self, _view, value => $$invalidate(13, $_view = value));
	component_subscribe($$self, _viewDates, value => $$invalidate(1, $_viewDates = value));
	component_subscribe($$self, noEventsClick, value => $$invalidate(14, $noEventsClick = value));
	component_subscribe($$self, noEventsContent, value => $$invalidate(11, $noEventsContent = value));
	component_subscribe($$self, theme, value => $$invalidate(3, $theme = value));
	let noEvents, content;

	function handleClick(jsEvent) {
		if (is_function($noEventsClick)) {
			$noEventsClick({
				jsEvent,
				view: toViewWithLocalDates($_view)
			});
		}
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_viewDates, $_events*/ 4098) {
			{
				$$invalidate(2, noEvents = true);

				if ($_viewDates.length) {
					let start = $_viewDates[0];
					let end = addDay(cloneDate($_viewDates[$_viewDates.length - 1]));

					for (let event of $_events) {
						if (!bgEvent(event.display) && event.start < end && event.end > start) {
							$$invalidate(2, noEvents = false);
							break;
						}
					}
				}
			}
		}

		if ($$self.$$.dirty & /*$noEventsContent, content*/ 2049) {
			{
				$$invalidate(0, content = is_function($noEventsContent)
				? $noEventsContent()
				: $noEventsContent);

				if (typeof content === 'string') {
					$$invalidate(0, content = { html: content });
				}
			}
		}
	};

	return [
		content,
		$_viewDates,
		noEvents,
		$theme,
		_events,
		_view,
		_viewDates,
		noEventsClick,
		noEventsContent,
		theme,
		handleClick,
		$noEventsContent,
		$_events
	];
}

class View extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

var index = {
	createOptions(options) {
		// Common options
		options.buttonText.listDay = 'list';
		options.buttonText.listWeek = 'list';
		options.buttonText.listMonth = 'list';
		options.buttonText.listYear = 'list';
		options.listDayFormat = {weekday: 'long'};
		options.listDaySideFormat = {year: 'numeric', month: 'long', day: 'numeric'};
		options.noEventsClick = undefined;  // ec option
		options.noEventsContent = 'No events';
		options.theme.daySide = 'ec-day-side';
		options.theme.eventTag = 'ec-event-tag';
		options.theme.noEvents = 'ec-no-events';
		options.view = 'listWeek';
		options.views.listDay = {
			buttonText: btnTextDay,
			component: View,
			duration: {days: 1},
			theme: themeView('ec-list ec-day-view')
		};
		options.views.listWeek = {
			buttonText: btnTextWeek,
			component: View,
			duration: {weeks: 1},
			theme: themeView('ec-list ec-week-view')
		};
		options.views.listMonth = {
			buttonText: btnTextMonth,
			component: View,
			duration: {months: 1},
			theme: themeView('ec-list ec-month-view')
		};
		options.views.listYear = {
			buttonText: btnTextYear,
			component: View,
			duration: {years: 1},
			theme: themeView('ec-list ec-year-view')
		};
	},

	createStores(state) {
		state._intlListDay = intl(state.locale, state.listDayFormat);
		state._intlListDaySide = intl(state.locale, state.listDaySideFormat);
	}
};

export { index as default };
